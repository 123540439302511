import { BasicTable, CustomText } from "@dreamvps/dream-ui/dist";
import { useAjax, useConfirm } from "@dreamvps/dream-utils/dist/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { io } from "../utils/socket";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "reactstrap";
import { ReactComponent as MenuSvg } from "../svgs/menu.svg";
import AddAgentModal from "../modals/add-agent";
import { debounce } from "../utils/debounce";
import MassOnboardingModal from "../modals/mass-onboarding";
import AddCSVLink from "../modals/add-csv";
import CustomPagination from "../pagination";
import { toast } from "react-toastify";
const groupDetails = {
  name: "X",
  createdDate: "10/05/2020",
  agents: 10,
  owner: "XYZ",
};

function Policy() {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalData, setTotalData] = useState({
    bot: {
      enabled: false,
    },
    trueType: { enabled: false },
    dlp: { enabled: false },
    dataSourcing: { enabled: false },
    remoteChecking: true,
    autoMonitor: true,
  });
  const ajax = useAjax();
  const [agents, setAgents] = useState([]);
  const confirm = useConfirm();
  const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isMassOnboardingModalOpen, setMassOnboardingModalOpen] =
    useState(false);
  const [addCSVModalOpen, setAddCSVModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataSourceQuery, setDataSourceQuery] = useState({
    page: 1,
    limit: 10,
  });
  const [agentsQuery, setAgentsQuery] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [totalAgents, setTotalAgents] = useState(0);
  const [totalDataSourceAgents, setTotalDataSourceAgents] = useState(0);
  const [dataSourceAgents, setDataSourceAgents] = useState([]);

  const setFilter = (value) => {
    setAgentsQuery((prev) => ({ ...prev, search: value, page: 1 }));
  };
  const debouncedSetSearch = debounce(setFilter);

  const getDataSourceAgents = useCallback(async () => {
    const data = await ajax(
      `/agent-groups/1/policies/data-source-list?limit=${dataSourceQuery.limit}&page=${dataSourceQuery.page}`
    );
    setDataSourceAgents(data.dataSourceListPaged);
    setTotalDataSourceAgents(data.total);
  }, [ajax, dataSourceQuery]);

  const getAgentGroupPolicies = useCallback(async () => {
    const data = await ajax(`/agent-groups/1/policies`);
    const bot = data.policies[0].bot;
    const trueTypes = data.policies[0].trueType;
    const dlp = data.policies[0].dlp;
    const dataSourcing = data.policies[0].dataSourcing;
    const remoteChecking = data.policies[0].remoteChecking;
    const autoMonitor = data.policies[0].autoMonitor;
    let botConfig = { enabled: bot.enabled };
    if (botConfig.enabled) {
      if (bot.alertEvery) {
        botConfig = { ...botConfig, alertEvery: bot.alertEvery };
      }
      if (bot.alertType === 1 || bot.alertType === 0) {
        botConfig = { ...botConfig, alertType: bot.alertType };
      }
      botConfig = { ...botConfig, message: bot.message };
    }
    let trueTypeConfig = {
      enabled: trueTypes.enabled,
      direction: trueTypes.direction,
    };
    if (trueTypeConfig.enabled) {
      if (trueTypes.withRemoteSdeScan.action) {
        trueTypeConfig = {
          ...trueTypeConfig,
          action: trueTypes.withRemoteSdeScan.action,
        };
      }
      if (trueTypes.withRemoteSdeScan.fileTypes) {
        trueTypeConfig = {
          ...trueTypeConfig,
          patterns: [...trueTypes.withRemoteSdeScan.fileTypes],
        };
      }
    }

    let dlpConfig = {
      enabled: dlp.contentFiltering.enabled && dlp.fileFiltering.enabled,
      direction: dlp.contentFiltering.direction,
    };
    if (dlpConfig.enabled) {
      if (dlp.contentFiltering.action) {
        dlpConfig = {
          ...dlpConfig,
          action: Number(dlp.contentFiltering.action),
        };
      }
      if (dlp.contentFiltering.patterns) {
        dlpConfig = {
          ...dlpConfig,
          patterns: [...dlp.contentFiltering.patterns],
        };
      }
    }
    let dataSourcingConfig = { enabled: dataSourcing.enabled };
    const totalDataConfig = {
      bot: { ...botConfig },
      dlp: { ...dlpConfig },
      trueType: { ...trueTypeConfig },
      dataSourcing: { ...dataSourcingConfig },
      remoteChecking,
      autoMonitor,
    };
    setTotalData({ ...totalDataConfig });
  }, [ajax]);
  useEffect(() => {
    getAgentGroupPolicies();
  }, [getAgentGroupPolicies]);

  useEffect(() => {
    getDataSourceAgents();
  }, [getDataSourceAgents]);

  const listAgents = useCallback(async () => {
    const data = await ajax(
      `/agents/list/1?search=${agentsQuery.search}&page=${agentsQuery.page}&limit=${agentsQuery.limit}`
    );
    setAgents(data.agents);
    setTotalAgents(data.total);
  }, [ajax, agentsQuery]);

  useEffect(() => {
    listAgents();
  }, [listAgents]);

  useEffect(() => {
    function handleAgentAuthenticated() {
      listAgents();
    }

    function handleAgentDisconnected(data) {
      const agent = agents.find((item) => item.id === data.agentID);

      if (agent) {
        agent.state = null;
      }

      setAgents([...agents]);
    }

    io.on("authenticated", handleAgentAuthenticated);
    io.on("disconnected", handleAgentDisconnected);

    return () => {
      io.off("authenticated", handleAgentAuthenticated);
      io.off("disconnected", handleAgentDisconnected);
    };
  }, [listAgents, agents]);

  function handleEditAgentClicked(agent) {
    setSelectedAgent(agent);
    setIsAddAgentModalOpen(true);
  }

  function handleAddNewAgentClicked() {
    setIsAddAgentModalOpen(true);
  }
  async function handleAddAgentModalClosed(state) {
    if (state) {
      await listAgents();
    }

    setIsAddAgentModalOpen(false);
    setSelectedAgent(null);
  }

  function handleMassOnboardingClicked() {
    setMassOnboardingModalOpen(true);
  }

  async function handleMassOnboardingModalClosed(state) {
    if (state) {
      await listAgents();
    }

    setMassOnboardingModalOpen(false);
  }

  function handleRemoveAgentClicked(agent) {
    confirm({
      title: "Remove agent",
      message: `Are you sure you want to remove ${agent.first_name}?`,
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/agents/remove", { agentID: agent.id });
        handleAddAgentModalClosed(true);
      },
    });
  }

  function allowBotAlertMonitoring(data) {
    setTotalData((prev) => ({
      ...prev,
      bot: { ...prev.bot, alertType: data },
    }));
  }
  function allowBotAlertMin(data) {
    setTotalData((prev) => ({
      ...prev,
      bot: { ...prev.bot, alertEvery: Number(data) || null },
    }));
  }

  function trueTypeSaveAndDelete(data) {
    setTotalData((prev) => ({
      ...prev,
      trueType: { ...prev.trueType, action: data },
    }));
  }

  function trueTypeDirection(direction) {
    setTotalData((prev) => ({
      ...prev,
      trueType: { ...prev.trueType, direction },
    }));
  }
  function dlpDirection(direction) {
    setTotalData((prev) => ({
      ...prev,
      dlp: { ...prev.dlp, direction },
    }));
  }

  function dlpSaveAndDelete(data) {
    setTotalData((prev) => ({ ...prev, dlp: { ...prev.dlp, action: data } }));
  }

  function setdlpPattern(data) {
    const dataArray = data.split("\n");
    setTotalData((prev) => ({
      ...prev,
      dlp: { ...prev.dlp, patterns: [...dataArray] },
    }));
  }
  function setTrueTypePattern(data) {
    const dataArray = data.split("\n");
    setTotalData((prev) => ({
      ...prev,
      trueType: { ...prev.trueType, patterns: [...dataArray] },
    }));
  }
  async function onSubmit() {
    setDisableSubmit(true);
    try {
      await ajax("/agent-groups/1/policies/edit", { ...totalData });
      await getDataSourceAgents();
      toast.success("Policies updated successfully");
    } catch (err) {
      toast.error(err.message);
    }
    setDisableSubmit(false);
  }

  function handleAddCSVModalClose() {
    setAddCSVModalOpen(false);
  }
  function setCSVLink(csvLink) {
    setTotalData((prev) => ({
      ...prev,
      dataSourcing: { ...prev.dataSourcing, listPath: csvLink },
    }));
  }

  const agentPrevPage = () => {
    setAgentsQuery((prev) => ({ ...prev, page: prev.page - 1 }));
  };
  const agentNextPage = () => {
    setAgentsQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const dataSourcePrevPage = () => {
    setDataSourceQuery((prev) => ({ ...prev, page: prev.page - 1 }));
  };
  const dataSourceNextPage = () => {
    setDataSourceQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  return (
    <div style={{ width: "80%", marginRight: "20px" }}>
      <Row
        style={{
          overflowY: "auto",
          height: "100vh",
          paddingTop: "100px",
        }}
      >
        <Row>
          <Col
            md={6}
            style={{
              border: "1px solid",
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <div style={{ padding: "10px 25px" }}>
              <Row className="justify-content-between mb-4">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTotalData((prev) => ({
                          ...prev,
                          dataSourcing: {
                            ...prev.dataSourcing,
                            enabled: e.target.checked,
                          },
                        }));
                      } else {
                        setTotalData((prev) => ({
                          ...prev,
                          dataSourcing: { enabled: e.target.checked },
                        }));
                      }
                    }}
                    checked={totalData.dataSourcing.enabled}
                  />
                  <p className="ms-2 mb-0" title="Enables monitoring">
                    User data source
                  </p>
                </Label>
                {totalData.dataSourcing.enabled ? (
                  <Row>
                    <Row className="d-flex mt-3 align-items-center">
                      <h5 className="w-75 mb-0">Data Source Table</h5>{" "}
                      <button
                        className="btn btn-primary w-25"
                        onClick={() => setAddCSVModalOpen(true)}
                      >
                        CSV
                      </button>{" "}
                    </Row>
                    <Row className="mt-3">
                      <Card
                        body
                        outline
                        color="secondary"
                        className="w-100"
                        style={{ overflowX: "auto" }}
                      >
                        <BasicTable style={{ margin: "20px" }}>
                          <thead>
                            <tr>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataSourceAgents.map((item, index) => (
                              <tr key={index}>
                                <td>{item["first names"]}</td>
                                <td>{item["last names"]}</td>
                                <td>{item.Email}</td>
                                <td>{item.Phonenumber}</td>
                              </tr>
                            ))}
                          </tbody>
                        </BasicTable>
                      </Card>
                      <CustomPagination
                        page={dataSourceQuery.page}
                        totalPages={Math.ceil(
                          totalDataSourceAgents / dataSourceQuery.limit
                        )}
                        onPrev={dataSourcePrevPage}
                        onNext={dataSourceNextPage}
                        disableNext={
                          (dataSourceQuery.page - 1) * dataSourceQuery.limit +
                            dataSourceAgents.length ===
                          totalDataSourceAgents
                        }
                      />
                    </Row>
                  </Row>
                ) : null}
                <AddCSVLink
                  isOpen={addCSVModalOpen}
                  onClose={handleAddCSVModalClose}
                  setCSVLink={setCSVLink}
                />
              </Row>
              <Row className="justify-content-between mb-4">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      setTotalData((prev) => ({
                        ...prev,
                        bot: { ...prev.bot, enabled: e.target.checked },
                      }))
                    }
                    checked={totalData.bot.enabled}
                  />
                  <p className="ms-2 mb-0" title="Enables bot monitoring">
                    Allow Bot
                  </p>
                </Label>
                {totalData.bot.enabled ? (
                  <Row className="ms-3">
                    <Label className="d-flex">
                      <Input
                        type="radio"
                        name="alertMonitoring"
                        onChange={() => allowBotAlertMonitoring(0)}
                        checked={totalData.bot.alertType === 0}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Gives alert for monitoring only"
                      >
                        Alert of monitoring only
                      </p>
                    </Label>
                    <Label className="d-flex mt-2">
                      <Input
                        type="radio"
                        name="alertMonitoring"
                        onChange={() => allowBotAlertMonitoring(1)}
                        checked={totalData.bot.alertType === 1}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Takes permission from user for monitoring"
                      >
                        Ask user for monitoring
                      </p>
                    </Label>
                    <Row className="mt-2 align-items-center">
                      <span className="w-25">Every</span>{" "}
                      <CustomText
                        placeholder="1440"
                        className="w-50"
                        onChange={(e) => allowBotAlertMin(e.target.value)}
                        min={1}
                        type="number"
                        value={totalData.bot.alertEvery || null}
                      />{" "}
                      <span className="w-25">Minutes</span>
                    </Row>
                  </Row>
                ) : null}
              </Row>
              <Row className="mb-4 justify-content-center">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      setTotalData((prev) => ({
                        ...prev,
                        remoteChecking: e.target.checked,
                      }))
                    }
                    checked={totalData.remoteChecking}
                  />
                  <p
                    className="ms-2 mb-0"
                    title="Checks actual type of the the file detected"
                  >
                    Remote Checking
                  </p>
                </Label>
              </Row>
              <Row className="mb-4 justify-content-center">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      setTotalData((prev) => ({
                        ...prev,
                        autoMonitor: e.target.checked,
                      }))
                    }
                    checked={totalData.autoMonitor}
                  />
                  <p
                    className="ms-2 mb-0"
                    title="Checks actual type of the the file detected"
                  >
                    Monitor by default
                  </p>
                </Label>
              </Row>
              <Row className="mb-4 justify-content-center">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      setTotalData((prev) => ({
                        ...prev,
                        trueType: {
                          ...prev.trueType,
                          enabled: e.target.checked,
                        },
                      }))
                    }
                    checked={totalData.trueType.enabled}
                  />
                  <p
                    className="ms-2 mb-0"
                    title="Checks actual type of the the file detected"
                  >
                    True Type
                  </p>
                </Label>
                {totalData.trueType.enabled ? (
                  <Row className="ms-3">
                    <Label className="d-flex">
                      <Input
                        type="radio"
                        name="trueTypeSaveAndDelete"
                        onChange={() => trueTypeSaveAndDelete(1)}
                        checked={totalData.trueType.action === 1}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Deletes flagged content after saving."
                      >
                        Save and Delete
                      </p>
                    </Label>
                    <Label className="d-flex mt-2">
                      <Input
                        type="radio"
                        name="trueTypeSaveAndDelete"
                        onChange={() => trueTypeSaveAndDelete(2)}
                        checked={totalData.trueType.action === 2}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Deletes flagged content without saving"
                      >
                        Delete Only
                      </p>
                    </Label>
                    <Row className="mt-2">
                      <span className="w-25">Pattern</span>
                      <div className="w-75">
                        <textarea
                          rows={3}
                          onChange={(e) => setTrueTypePattern(e.target.value)}
                          value={
                            totalData.trueType.patterns
                              ? totalData.trueType.patterns.join("\n")
                              : null
                          }
                        />{" "}
                        <br />
                        <span className="text-muted">
                          Enter patterns separated by new line
                        </span>
                      </div>
                    </Row>
                    <Row className="mt-2">
                      <span className="w-25">Direction</span>
                      <div className="w-75">
                        <Label className="d-flex">
                          <Input
                            type="radio"
                            name="trueTypeDirection"
                            onChange={() => trueTypeDirection(1)}
                            checked={totalData.trueType.direction === 1}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content after saving."
                          >
                            Incoming
                          </p>
                        </Label>
                        <Label className="d-flex mt-2">
                          <Input
                            type="radio"
                            name="trueTypeDirection"
                            onChange={() => trueTypeDirection(2)}
                            checked={totalData.trueType.direction === 2}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content without saving"
                          >
                            Outgoing
                          </p>
                        </Label>
                        <Label className="d-flex mt-2">
                          <Input
                            type="radio"
                            name="trueTypeDirection"
                            onChange={() => trueTypeDirection(0)}
                            checked={totalData.trueType.direction === 0}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content after saving."
                          >
                            Bi-directional
                          </p>
                        </Label>
                      </div>
                    </Row>
                  </Row>
                ) : null}
              </Row>
              <Row className="mb-4 justify-content-center">
                <Label className="d-flex">
                  <Input
                    type="checkbox"
                    onChange={(e) =>
                      setTotalData((prev) => ({
                        ...prev,
                        dlp: { ...prev.dlp, enabled: e.target.checked },
                      }))
                    }
                    checked={totalData.dlp.enabled}
                  />
                  <p
                    className="ms-2 mb-0"
                    title="Data loss prevention monitoring"
                  >
                    DLP
                  </p>
                </Label>
                {totalData.dlp.enabled ? (
                  <Row className="ms-3">
                    <Label className="d-flex">
                      <Input
                        type="radio"
                        name="dlpSaveDelete"
                        onChange={() => dlpSaveAndDelete(1)}
                        checked={totalData.dlp.action === 1}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Deletes flagged content after saving."
                      >
                        Save and Delete
                      </p>
                    </Label>
                    <Label className="d-flex mt-2">
                      <Input
                        type="radio"
                        name="dlpSaveDelete"
                        onChange={() => dlpSaveAndDelete(2)}
                        checked={totalData.dlp.action === 2}
                      />
                      <p
                        className="ms-2 mb-0"
                        title="Deletes flagged content without saving"
                      >
                        Delete Only
                      </p>
                    </Label>
                    <Row className="mt-2">
                      <span className="w-25">Pattern</span>
                      <div className="w-75">
                        <textarea
                          rows={3}
                          onChange={(e) => setdlpPattern(e.target.value)}
                          value={
                            totalData.dlp.patterns
                              ? totalData.dlp.patterns.join("\n")
                              : null
                          }
                        />{" "}
                        <br />
                        <span className="text-muted">
                          Enter patterns separated by new line
                        </span>
                      </div>
                    </Row>
                    <Row className="mt-2">
                      <span className="w-25">Direction</span>
                      <div className="w-75">
                        <Label className="d-flex">
                          <Input
                            type="radio"
                            name="dlpDirection"
                            onChange={() => dlpDirection(1)}
                            checked={totalData.dlp.direction === 1}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content after saving."
                          >
                            Incoming
                          </p>
                        </Label>
                        <Label className="d-flex mt-2">
                          <Input
                            type="radio"
                            name="dlpDirection"
                            onChange={() => dlpDirection(2)}
                            checked={totalData.dlp.direction === 2}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content without saving"
                          >
                            Outgoing
                          </p>
                        </Label>
                        <Label className="d-flex mt-2">
                          <Input
                            type="radio"
                            name="dlpDirection"
                            onChange={() => dlpDirection(0)}
                            checked={totalData.dlp.direction === 0}
                          />
                          <p
                            className="ms-2 mb-0"
                            title="Deletes flagged content after saving."
                          >
                            Bi-directional
                          </p>
                        </Label>
                      </div>
                    </Row>
                  </Row>
                ) : null}
              </Row>
              <Row className="justify-content-end">
                <Button
                  className="d-flex w-25 justify-content-center align-items-center"
                  onClick={onSubmit}
                  disabled={disableSubmit}
                >
                  Save
                </Button>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div
              style={{
                border: "1px solid",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderRadius: "20px",
                overflow: "hidden",
                marginBottom: "20px",
              }}
            >
              <Row className="mb-4">
                <h5 className="text-center">Group details</h5>
              </Row>
              <Card
                body
                outline
                color="secondary"
                style={{
                  overflowY: "auto",
                  width: "100%",
                  border: "none",
                }}
              >
                <ListGroup>
                  <ListGroupItem>Name: {groupDetails.name}</ListGroupItem>
                  <ListGroupItem>Owner: {groupDetails.owner}</ListGroupItem>
                  <ListGroupItem>
                    Created date: {groupDetails.createdDate}
                  </ListGroupItem>
                  <ListGroupItem>Agents: {groupDetails.agents}</ListGroupItem>
                </ListGroup>
              </Card>
            </div>

            <div
              style={{
                border: "1px solid",
                padding: "10px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <div className="d-flex align-items-center mb-3">
                <div>
                  <CustomText
                    placeholder="Search agents..."
                    onChange={(e) => debouncedSetSearch(e.target.value)}
                  />
                </div>
                <h5 className="mb-0 ms-3">Group agent list</h5>
                <div className="buttons d-flex">
                  <button
                    className="btn btn-primary mx-2"
                    onClick={handleMassOnboardingClicked}
                  >
                    Import
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddNewAgentClicked}
                  >
                    Add agent
                  </button>
                </div>
              </div>
              <Card
                body
                outline
                color="secondary"
                style={{ overflowY: "auto", width: "100%" }}
              >
                <BasicTable style={{ margin: "20px" }}>
                  <thead style={{ margin: "20px" }}>
                    <tr>
                      <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                        ID
                      </th>
                      <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                        Name
                      </th>
                      <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                        Department
                      </th>
                      <th style={{ marginBottom: "20px", fontWeight: "bold" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!agents && (
                      <tr>
                        <td colSpan={7}>
                          <Spinner />
                        </td>
                      </tr>
                    )}

                    {agents?.length === 0 && (
                      <tr>
                        <td colSpan={7}>No agents</td>
                      </tr>
                    )}

                    {agents?.map((agent, key) => (
                      <tr key={key}>
                        <td>#{agent.id}</td>
                        <td>
                          {agent.first_name} {agent.last_name}{" "}
                          {/* {agent.is_bot ? <strong>(Bot)</strong> : null} */}
                        </td>
                        <td>{agent.department}</td>
                        <td>
                          <MenuSvg
                            onClick={() => handleEditAgentClicked(agent)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              </Card>
              <CustomPagination
                page={agentsQuery.page}
                totalPages={Math.ceil(totalAgents / agentsQuery.limit)}
                onPrev={agentPrevPage}
                onNext={agentNextPage}
                disableNext={
                  (agentsQuery.page - 1) * agentsQuery.limit + agents.length ===
                  totalAgents
                }
              />
              <AddAgentModal
                isOpen={isAddAgentModalOpen}
                onClose={handleAddAgentModalClosed}
                onDelete={handleRemoveAgentClicked}
                agent={selectedAgent}
                deleteIcon
              />
              <MassOnboardingModal
                isOpen={isMassOnboardingModalOpen}
                onClose={handleMassOnboardingModalClosed}
              />
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
}

export default Policy;
